/* Google Fonts Import */
@import url("https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");

/* Local Font (Dynamo) Import */
@font-face {
  font-family: "Dynamo W04 Bold Condensed";
  src: url("./Dynamo W04 Bold Condensed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Global CSS Variables and Reset */
* {
  --dark-bg: #1c2327;
  --orange: #fd8a58;
  --aqua: #6eecf4;
  --cyan-bg: #507378;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  /* Font Variables */
  --title-font: "Dynamo W04 Bold Condensed";
  --body-font: "Manrope", Helvetica, Arial, sans-serif;
  --hand-font: "Yellowtail", cursive;
}

/* Example usage of custom fonts */
body {
  font-family: var(--body-font);
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: var(--title-font);
}

.handwriting {
  font-family: var(--hand-font);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, var(--orange)),
    color-stop(0.72, rgb(255, 208, 0)),
    color-stop(0.86, rgb(221, 135, 22))
  );
}
