.clients-section {
  padding: 50px 20px;
  background-image: linear-gradient(
      135deg,
      transparent 0%,
      transparent 6%,
      rgba(71, 71, 71, 0.04) 6%,
      rgba(71, 71, 71, 0.04) 22%,
      transparent 22%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 20%,
      rgba(71, 71, 71, 0.04) 20%,
      rgba(71, 71, 71, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 24%,
      rgba(71, 71, 71, 0.04) 24%,
      rgba(71, 71, 71, 0.04) 62%,
      transparent 62%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 73%,
      rgba(71, 71, 71, 0.04) 73%,
      rgba(71, 71, 71, 0.04) 75%,
      transparent 75%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255)); /* Light background to make logos stand out */
  text-align: center;
}

.clients-title {
  font-size: 56px;
  color: #333;
  margin-bottom: 40px;
}

.clients-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Responsive grid */
  gap: 30px;
  justify-items: center;
}

.client-logo-container {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.client-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(100%); /* Start in grayscale */
  transition: filter 0.4s ease, transform 0.4s ease; /* Smooth transition */
}

.client-logo-container:hover .client-logo {
  filter: grayscale(0%); /* Turn to full color on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
}
