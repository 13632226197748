/* General styles for AppBar and Toolbar */
.appbar {
  background-color: rgba(0, 0, 0, 0.8); /* Background color */
  box-shadow: none;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo styles */
.site-branding img {
  width: 120px;
  padding: 20px 0;
}

/* Desktop Menu styles */
.desktop-menu {
  display: none; /* Hidden by default */
}

.mainmenu {
  list-style: none;
  display: flex;
  gap: 20px;
}

.mainmenu li a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  font-size: 18px;
  font-family: var(--body-font);
  font-weight: bold;
  transition: color 0.3s ease;
}

.mainmenu li a:hover {
  color: #fd8a58; /* Hover color matching your branding */
}

/* Hamburger (mobile menu) styles */
.hamburger {
  color: white; /* Set color to white for both icons */
  display: none; /* Hidden by default */
}

/* Drawer styles for mobile menu */
.drawer-list {
  width: 250px;
  padding-top: 20px;
  background-color: rgba(0, 0, 0, 0.9); /* Dark background for the drawer */
  height: 100%;
  color: white;
}

.drawer-link {
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s ease;
}

.drawer-link:hover {
  color: #fd8a58; /* Hover color for the drawer links */
}

/* Media Queries */

/* Show hamburger and hide desktop menu on small screens */
@media (max-width: 768px) {
  .desktop-menu {
    display: none; /* Hide desktop menu on small screens */
  }

  .hamburger {
    display: block; /* Show hamburger icon on small screens */
  }
}

/* Show desktop menu and hide hamburger on large screens */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* Hide hamburger icon on large screens */
  }

  .desktop-menu {
    display: flex; /* Show desktop menu on large screens */
  }
}
