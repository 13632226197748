.footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
  text-align: center;
  position: relative;
  animation: fadeInUp 1.5s ease-in-out;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.1);
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-socials a {
  color: white;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.footer-socials a:hover {
  transform: translateY(-5px);
  color: var(--orange); /* Add a hover color for effect */
}

.footer-copyright p {
  margin: 5px 0;
  font-size: 16px;
}

/* Fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-logo img {
    width: 100px;
  }

  .footer-socials a {
    font-size: 20px;
  }

  .footer-copyright p {
    font-size: 14px;
  }
}
