.client-relationships {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  padding: 50px;
  background-color: #e0f7fa;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.client-text {
  order: 1;
}

.client-text h2 {
  font-size: 50px;
}
.client-text p {
  font-size: 26px;
}

.client-relationships img {
  border-radius: 50%;
  width: 150px;
  order: 2;
}

@media (max-width: 768px) {
  .client-text {
    text-align: center;
    order: 2;
  }
  .client-relationships {
    flex-direction: column;
  }
  .client-relationships img {
    width: 250px;
    order: 1;
  }
}
