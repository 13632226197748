/* Hero Section styles */
.hero-section {
  background: linear-gradient(to right, var(--dark-bg), var(--cyan-bg));
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.hero-title {
  opacity: 0;
}

.hero-subtitle {
  opacity: 0;
}

/* Mascot styles */
.mascot-container {
  position: relative;
}

.mascot {
  width: 450px;
  height: auto;
  position: relative;
  opacity: 1;
  transition: transform 0.3s ease;
}

.mascot:hover {
  transform: scale(1.1);
}

.scroll-indicator {
  text-align: center;
}

.scroll-down-arrow {
  display: block;
  font-size: 24px;
  margin-top: 5px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .mascot {
    width: 450px;
    margin-bottom: 20px;
  }

  .hero-content {
    text-align: center;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-subtitle {
    font-size: 18px;
  }
}
