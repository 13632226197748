.home {
  background-color: var(--dark-bg);
  color: #fff;
}

.about-section {
  text-align: center;
  padding: 100px 70px;
}

.about-section h2 {
  font-size: 42px;
  margin-bottom: 20px;
  opacity: 0; /* Initial state for animation */
  transform: translateY(50px); /* Start position for animation */
}

.about-section p {
  font-size: 26px;
  margin: 10px 20px;
  opacity: 0; /* Initial state for animation */
  transform: translateY(100px); /* Start position for animation */
}

.why-section {
  background: linear-gradient(to right, var(--dark-bg), var(--cyan-bg));
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.why-title {
  font-size: 42px;
  margin-top: 50px;
}

.why-box {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.why-box > div {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease-in-out;
}

.why-box > div:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px 2px var(--cyan-bg), 0 0 30px 10px var(--aqua);
}

@media (max-width: 768px) {
  .about-section {
    text-align: center;
    padding: 100px 40px;
  }

  .about-section h2 {
    font-size: 42px;
    margin-bottom: 20px;
  }

  .about-section p {
    font-size: 20px;
    margin: 10px 20px;
  }
}
