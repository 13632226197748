.data-driven-campaigns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  padding: 50px;
  background-color: #fff3e0;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.data-text {
  order: 1;
}

.data-text h2 {
  font-size: 50px;
}
.data-text p {
  font-size: 26px;
}
.data-driven-campaigns img {
  border-radius: 50%;
  width: 150px;
  order: 2;
}

@media (max-width: 768px) {
  .data-text {
    text-align: center;
    order: 2;
  }
  .data-driven-campaigns {
    flex-direction: column;
  }
  .data-driven-campaigns img {
    width: 250px;
    order: 1;
  }
}
